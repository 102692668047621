<template>
  <div class="amount-wrapper" :style="{ 'font-family': fontFamily }">
    <InputInteger
      :value="value"
      ref="parlayAmount"
      :input-classes="['with-currency']"
      maxlength="6"
      :section-name="sectionName"
      :font-family="fontFamily"
      @input="$emit('input', $event)"
    />
    <span class="amount-wrapper__symbol" @click="onAmountClicked">$</span>
    <span class="amount-wrapper__currency" @click="onAmountClicked">mxn</span>
  </div>
</template>

<script>
export default {
  name: 'InputAmount',
  components: {
    InputInteger: () => import('@/components/Form/InputInteger'),
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    fontFamily: {
      type: String,
      default: 'inherit',
    },
    sectionName: {
      type: String,
      default: '',
    },
  },
  methods: {
    onAmountClicked() {
      this.$refs.parlayAmount.$el.select();
    },
  },
};
</script>

<style scoped lang="scss">
.amount-wrapper {
  width: fit-content;
  position: relative;
  font-size: 1rem;

  & > span {
    position: absolute;
    color: #272727;
    font-weight: bold;

    &.amount-wrapper__symbol {
      left: 0.7em;
      top: 0.18em;
      font-size: 1.5em;
    }

    &.amount-wrapper__currency {
      right: 0.4em;
      bottom: 0.19em;
      font-size: 0.8em;
      text-transform: uppercase;
    }
  }

  &::v-deep .with-currency {
    font-size: 1.75em;
    padding: 0 1.6em 0 1.2em;
    width: 100%;
    max-width: 7em;
    line-height: 0.6;
    font-weight: bold;
    color: #272727;
    height: 1.4em !important;
  }
}
</style>
